import React,{useState, useEffect, Fragment} from 'react'
import './style.scss';
import Lottie from "react-lottie-player";
import lottieJson from "../../../../static/images/common/customer-form-lottie.json";

const Thankyou = () => {

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
 
  return (
  <Fragment>
    <div className='avmade-header'>
        <img src="/images/common/plus_new_logo.svg" />
    </div>
    <div className='avmade-body custom-thank-you'>
    <div className="lottie">
        {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 150, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
        </div>
        <div className='lottie-main'>
        Thanks for choosing <br />
        AVmade.
        </div>
        <div className='lottie-subtext'>
        We can’t wait to see you. If this is urgent <br />
        email us at mike@avendros.com or call <br />
        us at 1 (510) 751-3039
        </div>
    </div>
  </Fragment>
  );
}

export default Thankyou;